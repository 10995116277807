/* perfect-scrollbar */
.ps {
    -ms-touch-action: auto;
    touch-action: auto;
    overflow: hidden !important;
    -ms-overflow-style: none; 
    position: relative;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps {
        overflow: auto !important; 
    } 
}
.ps__rail-x {
    display: none;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    height: 7px;
    bottom: 0px;
    position: absolute;
}

.ps__rail-y {
    display: none;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    width: 7px;
    right: 0;
    position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
    display: block;
    background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
    opacity: 0.6;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
    background-color: #eee;
    opacity: 0.9;
}

.ps__thumb-x {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, height .2s ease-in-out;
    -webkit-transition: background-color .2s linear, height .2s ease-in-out;
    height: 4px;
    bottom: 1px;
    position: absolute;
}

.ps__thumb-y {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, width .2s ease-in-out;
    -webkit-transition: background-color .2s linear, width .2s ease-in-out;
    width: 4px;
    right: 1px;
    position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
    background-color: #999;
    height: 7px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
    background-color: #999;
    width: 7px;
}

@supports (-ms-overflow-style: none) {
    .ps {
        overflow: auto !important;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps {
        overflow: auto !important;
    }
}
  

//------------Smart-DataTable------
.ng2-smart-pagination-nav{
    text-align: center;
    margin-top: 20px;
    width: 100%;
}
.ng2-smart-pagination .page-link-next, 
.ng2-smart-pagination .page-link-prev{
    font-size: 12px !important;
}


//------------NGX-DataTable--------
.datatable-checkbox{
    padding: 0;
}
.datatable-checkbox input[type='checkbox']:before{
    border-color: #999;
}
.datatable-checkbox input[type='checkbox']:checked:before{
    border-color: #378D3B;
}

//----------Google Maps---------------
.sebm-google-map-container {
  height: 500px;
}
//----------Leaflet Maps---------------
#leaflet-map {
  width: 100%;
  height: 500px;
}


//--------Calendar-------------
.cal-event-action{
    color: #f5f5f5;
}


//---------widget---------------
.widget-controls{
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    padding: 1rem 1.25rem;
    font-size: 14px;
    a{
        font-size: 12px;
        margin-left: 7px;
        line-height: 1;
        vertical-align: top;
        display: inline-block;
        outline: none; 
        &.dropdown-toggle:after{
            display: none;
        }
        .fa-times{
            font-size: 14px;
        }
    }
    ul.dropdown-menu{
        min-width: 9rem;
        padding: 0;
        border-radius: 0;
        li{
            padding: 4px;
            overflow: hidden;
            a{
                color: #000;
                font-size: 13px; 
                width: 100%;           
            }
            &:hover{               
                background-color: blue;
            } 
            &:hover a{
                color: #555;
            }
        }
    }    
}

//--------angular-2-dropdown-multiselect-------------
.custom-multiselect{
    .dropdown{
        .input-group-addon{
            padding: .25rem .5rem;
            font-size: .875rem;
            border-radius: .2rem;
            background-color: #e9ecef;
            border: 1px solid #ced4da;
            color: #495057;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
            line-height: 1.4;
        }
        .input-group-btn>.btn{
            padding: .25rem .5rem;
            font-size: .875rem;
            line-height: 1.5;
            border-radius: .2rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: -1px;
        }
    }
}