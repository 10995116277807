$top-navbar-bg-color: #fff;
$top-navbar-text-color: #a6a6a6;
$logo-color: #000;
$logo-ng-color: #DD1B16;

$main-bg-color: #f5f5f5;
$main-text-color:#565656;

$info-content-bg-color: #ececec;
$info-content-icon-color: $top-navbar-text-color;

$messages-ddl-link-active-border-color: #ccc;

$user-menu-bg-color: #eceeef;
$user-menu-text-color: #262626;

$side-chat-item-color: #666666;
$side-chat-item-bg-color: #c2c2c2;
$side-chat-item-bottom-border-color: #f5f5f5;

$sidebar-bg-color: #ececec;
$active-link-bg-color: #dd1b16;
$sidebar-item-color: #666;
$sidebar-item-hover-color: #000;
$sidebar-item-hover-bg-color: $top-navbar-bg-color;
$sidebar-item-bottom-border-color: #fff;
$sidebar-item-bottom-border-shadow-color: #000;
$sidebar-subitem-bg-color: #999;

$mini-sidebar-tooltip-color: #fff;
$mini-sidebar-tooltip-bg-color: #262626;
$mini-sidebar-tooltip-arrow-color: #262626; 

$mini-horizontal-menu-tooltip-color: #fff;
$mini-horizontal-menu-tooltip-bg-color: #000;
$mini-horizontal-menu-tooltip-arrow-color: #000;

$footer-bg-color: #fff;

$box-shadow-color: #000;

$card-header-color: #404040;
$card-header-bg-color: #f3f3f3;

$login-window-bg-color: #ececec;
$login-header-bg-color: #9e9e9e;
$login-text-color: #696969; 


$widget-controls-color: #a6a6a6;

$form-control-focus-border-color: #666;


$primary-color: #3E50B4;
$dark-primary-color: #2F3E9E;
$success-color: #4BAE4F;
$dark-success-color: #378D3B;
$info-color: #00BBD3;
$dark-info-color: #0096A6;
$warning-color: #FE9700; 
$dark-warning-color: #F47B00;
$danger-color: #F34235;
$dark-danger-color: #D22E2E;
$gray-color: #9D9D9D;
$dark-gray-color: #606060;
$inverse-color: #292B2C;
$dark-inverse-color: #1C1E1F;

$breadcrumb-color: #3E50B4;
